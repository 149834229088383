import React from 'react';
import styled from 'styled-components';

const SSmolBlock = styled.div`
  max-width: ${(p) => (p.width ? p.width + 'px' : '500px')};
  width: 100%;
  box-sizing: border-box;
`;

const SText = styled.h3`
  font-family: var(--ff-headline);
  font-weight: 400;
  font-size: var(--fs-h3);
  line-height: 1.25;
  text-align: left;
  margin-right: calc(2 * var(--side-margin));

  .block {
    font-size: var(--fs-p);
  }
`;

const SSubtitle = styled.div`
  color: var(--c-fg-1);
`;

const SIcon = styled.div`
  height: calc(var(--icon-height));
  width: calc(var(--icon-width));
  flex-shrink: 0;
  flex-grow: 0;
  margin-bottom: 0.5rem;
  font-family: var(--ff-wide);
  font-weight: var(--fw-h3);
  font-size: var(--fs-h3);
  display: flex;
  align-items: center;
  justify-content: center;
  color: ${(p) => p.textColor};
  background: ${(p) => p.color};
  border-radius: var(--br-box);
  img {
    filter: var(--svgFilter);
  }
`;

const makeTitle = ({ title, subtitle }) => {
  return (
    <>
      {title}
      {!!subtitle && (
        <>
          <br />
          <SSubtitle>{subtitle}</SSubtitle>
        </>
      )}
    </>
  );
};

const SmolBlock = ({
  w,
  icon,
  title,
  subtitle,
  text,
  step,
  color = 'var(--c-border-light)',
  block,
  stepColor,
  ...props
}) => {
  return (
    <SSmolBlock width={w} {...props}>
      <SIcon icon={icon} color={color} textColor={stepColor}>
        {icon && <img loading="lazy" src={icon} alt={icon} height="40" />}
        {step}
      </SIcon>
      {!!text && (
        <SText>
          {!!title && <span className="m">{makeTitle({ title, subtitle })} </span>}
          <span className={`fg2 l ${block ? 'block' : ''}`}>{text}</span>
        </SText>
      )}
    </SSmolBlock>
  );
};

export default SmolBlock;
