import React from 'react';
import styled from 'styled-components';
import Helmet from 'react-helmet';
import { Portal } from 'react-portal';
import Toolbar from './Toolbar';

import { ReactComponent as Close } from 'src/iconic/close.svg';

const Modal = ({
  small,
  onClose,
  children,
  open,
  topItems,
  bottomItems,
  title,
  gray,
  mobileOnly,
  showToolbar = true,
}) => {
  return (
    <Portal>
      <SModalOuter open={open} onClick={onClose}>
        {!!open && (
          <Helmet>
            <meta
              name="theme-color"
              content="#777777"
              media={mobileOnly && '( max-width: 1450px)'}
            />
            <meta name="theme-color" content="#000000" media="(prefers-color-scheme: dark)" />
          </Helmet>
        )}
        <SModal
          open={open}
          small={small}
          onClick={(e) => e.stopPropagation()}
          gray={gray}
          hideToolbar={!showToolbar}
          showBottomBar={!!bottomItems}
        >
          {showToolbar && (
            <Toolbar modal top>
              <div className="modal-title">{title}</div>
              <div className="modal-close pressable" onClick={onClose}>
                <Close stroke="var(--c-fg-0)" />
              </div>
              {topItems}
            </Toolbar>
          )}
          <div className="modal-body">{children}</div>
          {bottomItems && (
            <Toolbar border modal>
              {bottomItems}
            </Toolbar>
          )}
        </SModal>
      </SModalOuter>
    </Portal>
  );
};

const SModalOuter = styled.div`
  position: fixed;
  top: 0;
  padding-bottom: var(--side-margin);
  height: 100%;
  right: 0;
  left: 0;
  bottom: 0px;
  overflow: hidden;
  padding-top: var(--s-nav-height);
  box-sizing: border-box;
  background: ${(p) => (p.open ? '#00000088' : 'transparent')};
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 999999999999999999999999999999999999999999999999999999999999999999999999;
  transition: background-color 0.35s;
  box-sizing: border-box;
  pointer-events: ${(p) => (p.open ? 'all' : 'none')};
  @media (max-width: 768px) {
    padding-bottom: 0;
    padding-top: 12px;
  }
`;

const SModal = styled.div<{ small?: boolean; hideToolbar?: boolean; showBottomBar?: boolean }>`
  opacity: ${(p) => (p.open ? 1 : 0)};
  transform: translateY(${(p) => (p.open ? '0%' : '100%')});
  background: ${(p) => (p.gray ? 'var(--c-bg-gray)' : 'var(--bgc)')};
  z-index: 99999999999999999999999999999999999999999999999999999999999999;

  color: var(--fg);

  max-height: ${({ small }) => (small ? 220 : 900)}px;
  max-width: ${({ small }) => (small ? 360 : 768)}px;
  width: 100%;
  height: 100%;

  border-radius: 24px;
  overflow: hidden;
  padding: 0;
  box-sizing: border-box;
  box-shadow: 0 0 100px #00000022;
  will-change: transform, opacity;
  transition: 0.35s cubic-bezier(0.23, 1, 0.32, 1);

  .modal-body {
    padding: var(--s-nav-height) var(--side-margin);
    overflow: scroll;
    position: fixed;
    background: transparent;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;

    ${({ hideToolbar }) => hideToolbar && 'padding-top: 0;'}
    ${({ showBottomBar }) => showBottomBar && 'padding-bottom: 64px;'}
  }
  .modal-title {
    position: absolute;
    width: 100%;
    left: 0;
    right: 0;
    text-align: center;
    margin: auto;
    z-index: -1;
    pointer-events: none;
    font-size: var(--fs-b2);
    font-weight: 500;
  }
  .modal-close {
    background: var(--c-bg-c);
    border-radius: 999px;
    height: 32px;
    display: flex;
    width: 32px;
    align-items: center;
    justify-content: center;
    margin-left: -6px;
    cursor: pointer;
    &:hover {
      opacity: 0.8;
    }
  }
  @media (max-width: 768px) {
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    padding: 0;
    border-radius: 39px 39px 0 0;
    max-height: none;
  }
`;

export default Modal;
