import React, { useMemo, useContext, useEffect, useRef, useState } from 'react';
import { getLuminance } from 'polished';
import styled from 'styled-components';

import { $t, ENV, locale, toggleLocale } from 'src/utils';
import { usePartner } from 'src/hooks';
import { Container, Logo, MNavLink, Progress } from 'src/components';
import paths from 'src/constants/paths';

// ICONS
import { ReactComponent as Guides } from 'src/iconic/book.svg';
import { ReactComponent as About } from 'src/iconic/information.svg';
import { ReactComponent as Partners } from 'src/iconic/file-text-2.svg';
import { ReactComponent as Press } from 'src/iconic/announcement.svg';
import { ReactComponent as Blog } from 'src/iconic/edit.svg';
import SessionContext from 'src/context/SessionContext';
import { NavMenu } from 'src/kit/base/NavMenu';
import { useIntercom } from 'src/lib';

export interface NavbarProps {
  banner?: React.ReactNode;
  color?: string;
  darkColor?: string;
  matchColor?: string;
  border?: boolean;
  hideLinks?: boolean;
  hideButtons?: boolean;
  unfix?: boolean;
  transparent?: boolean;
  progress?: number;
  isApp?: boolean;
  bg?: string;
  showBenefitsAdvisor?: boolean;
}

const SNavOuter = styled.div`
  width: 100%;
  max-width: 100%;
  height: calc(var(--s-nav-height));
  top: 0;
  position: ${(p) => (p.unfix ? 'absolute' : 'fixed')};
  z-index: 9999999;
  transition: transform 0.25s;
`;

const SNavBar = styled.div`
  width: 100%;
  max-width: 100%;
  height: var(--s-nav-height);
  bottom: 0;
  z-index: 9999999;
  position: absolute;
  box-sizing: border-box;
  border-bottom: ${(p) => (p.border ? '1px solid var(--c-border)' : '0px solid transparent')};
  transition: background 0.25s cubic-bezier(0.42, 0, 0.02, 1.18),
    box-shadow 0.25s cubic-bezier(0.42, 0, 0.02, 1.18);
  padding: 0 24px;
  backdrop-filter: blur(${(p) => (p.transparent ? 0 : 40)}px);
  color: ${(p) =>
    !!p.matchColor && !!p.color ? (getLuminance(p.color) > 0.4 ? 'black' : 'white') : 'var(--fg)'};
  background: ${(p) =>
    p.bg
      ? p.bg
      : !!p.matchColor && !!p.color
      ? p.color
      : p.solid
      ? 'var(--c-bg-nav)'
      : 'transparent'};

  .container {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    height: var(--s-nav-height);
  }
`;

const MNav = styled.div`
  background: var(--bg) !important;
  backdrop-filter: blur(40px);
  z-index: 99999999999999999999999999999999999999999;
  position: fixed;
  top: var(--s-nav-height);
  left: 0;
  right: 0;
  bottom: 0;
  background: var(--bgc);
  .navlink,
  .container {
    display: block;
  }
  .container {
    height: 100% !important;
    // padding: var(--side-margin);
  }
  .navlink {
    font-size: var(--fs-h3);
    padding: 12px 0;
  }
  .mnav {
    display: block;
  }
  a {
    font-size: var(--fs-b1) !important;
  }
`;

const links = [
  {
    text: $t({ id: 'nav.explorer', defaultMessage: 'Explore Plans' }),
    path: '/explorer',
  },
  {
    text: $t({ id: 'nav.resources', defaultMessage: 'Resources' }),
    path: '/guides/health',
    children: [
      {
        color: 'var(--g-cerise-light)',
        iconColor: 'var(--c-cerise-0)',
        title: $t({ id: 'nav.blog', defaultMessage: 'Blog' }),
        icon: Blog,
        url: '/blog',
      },
      {
        color: 'var(--g-cerise-light)',
        iconColor: 'var(--c-cerise-0)',
        title: $t({ id: 'nav.guide', defaultMessage: 'Guide' }),
        icon: Guides,
        url: '/guides/health',
      },
    ],
  },
  {
    text: $t({ id: 'nav.about', defaultMessage: 'About' }),
    path: '/about',
    children: [
      {
        color: 'var(--g-cerise-light)',
        iconColor: 'var(--c-cerise-0)',
        title: $t({ id: 'nav.about', defaultMessage: 'About' }),
        icon: About,
        url: '/about',
      },
      {
        color: 'var(--g-cerise-light)',
        iconColor: 'var(--c-cerise-0)',
        title: $t({ id: 'nav.partners', defaultMessage: 'Partners' }),
        icon: Partners,
        url: '/partners',
      },
      {
        color: 'var(--g-cerise-light)',
        iconColor: 'var(--c-cerise-0)',
        title: $t({ id: 'nav.press', defaultMessage: 'Press' }),
        icon: Press,
        url: '/press',
      },
    ],
  },
];

const ToggleNav = () => {
  var el = document.querySelector('body');
  el.classList.toggle('mnav');
};

/**
 *
 */
const Navbar: React.FC<NavbarProps> = ({
  banner,
  color,
  darkColor,
  matchColor,
  border = true,
  hideLinks,
  hideButtons,
  unfix,
  transparent,
  progress = 0,
  isApp,
  bg,
  showBenefitsAdvisor,
}) => {
  const { partner, signupUrl, signinUrl, hideLogo } = useContext(SessionContext);
  const { color: partnerColor, logo } = usePartner({ slug: partner });
  const [navBackground, setNavBackground] = useState(false);

  const navRef = useRef();
  const handleIntercomOpen = useIntercom();

  useEffect(() => {
    const handleScroll = () => {
      const show = window.scrollY > 100;
      if (navRef.current !== show) {
        setNavBackground(show);
      }
    };
    document.addEventListener('scroll', handleScroll);
    return () => {
      document.removeEventListener('scroll', handleScroll);
    };
  }, []);

  const { healthLinks, baLink, languageLink } = useMemo(() => {
    const baLink = {
      text: $t({
        id: 'nav.talk-to-advisor',
        defaultMessage: 'Get support',
      }),
      icon: 'Phone',
      path: paths.CONTACT,
    };

    const helpLink = {
      text: $t({ id: 'nav.help', defaultMessage: 'Help' }),
      icon: 'Help',
      onClick: handleIntercomOpen,
    };

    const languageLink = {
      text: locale === 'en' ? 'English' : 'Español',
      icon: 'Globe',
      onClick: toggleLocale,
    };

    const healthLinks = showBenefitsAdvisor
      ? [baLink, helpLink, languageLink]
      : [helpLink, languageLink];

    return {
      healthLinks,
      baLink,
      languageLink,
    };
  }, [toggleLocale]);

  return (
    <>
      <Progress progress={progress} color={partnerColor} />
      <SNavOuter transform={!!banner && !navBackground} unfix={unfix}>
        {!!banner && <div className="banner">{banner}</div>}
        <SNavBar
          banner={banner}
          bg={bg}
          solid={navBackground}
          color={color}
          darkColor={darkColor}
          matchColor={matchColor}
          border={border}
          transparent={transparent}
        >
          <Container full>
            <div>
              {!hideLogo && (
                <Logo
                  partner={partner}
                  logo={!!partner && partner !== 'catch' ? logo : null}
                  isApp={isApp}
                />
              )}
            </div>

            <div className="no-mobile">{!hideLinks && <NavMenu links={links} />}</div>

            <div>
              <div className="no-mobile">
                {!hideButtons ? (
                  <NavMenu
                    links={[
                      { text: '', icon: 'Help', onClick: handleIntercomOpen },
                      {
                        text: $t({ id: 'nav.sign-in', defaultMessage: 'Sign in' }),
                        path: signinUrl || ENV.appSignInUrl,
                      },
                      {
                        text: $t({ id: 'nav.sign-up', defaultMessage: 'Sign up' }),
                        path: signupUrl,
                      },
                    ]}
                  />
                ) : (
                  <NavMenu links={healthLinks} />
                )}
              </div>
              <div className="mobile-only">
                <NavMenu
                  links={[
                    { text: '', icon: 'Help', onClick: handleIntercomOpen },
                    { text: '', icon: 'Menu', onClick: ToggleNav },
                  ]}
                />
              </div>
            </div>
          </Container>
        </SNavBar>
      </SNavOuter>
      <MNav className="mnav-menu" banner={!!banner} unfix={unfix}>
        <Container>
          <div className="mnav">
            <nav>
              <MNavLink icon={baLink.icon} path={baLink.path} text={baLink.text} />
              {links.map((link) => (
                <MNavLink
                  key={`${link.text}-mobile`}
                  text={link.text}
                  path={!link.children && link.path}
                  onClick={!link.children && ToggleNav}
                >
                  {link.children &&
                    link.children.map((resource) => (
                      <MNavLink
                        key={resource.title}
                        text={resource.title}
                        path={resource.url}
                        onClick={ToggleNav}
                      />
                    ))}
                </MNavLink>
              ))}
              <MNavLink
                text={$t({ id: 'nav.sign-in', defaultMessage: 'Sign in' })}
                path={signinUrl}
              />
              <MNavLink
                text={$t({ id: 'nav.sign-up', defaultMessage: 'Sign up' })}
                path={signupUrl}
              />
              <MNavLink
                icon={languageLink.icon}
                text={languageLink.text}
                onClick={languageLink.onClick}
              />
            </nav>
          </div>
        </Container>
      </MNav>
    </>
  );
};

export default Navbar;
