const isClient = () => typeof window === 'object' || typeof window !== 'undefined';

export const share = ({ url, title, text }) => {
  if (!isClient() || !navigator) return null;
  try {
    navigator.share({ url, title, text });
  } catch (err) {
    alert("can't share");
  }
};

export const copy = ({ str = 'https://catch.co' }) => {
  const el = document.createElement('textarea');
  el.value = str;
  document.body.appendChild(el);
  el.select();
  document.execCommand('copy');
  document.body.removeChild(el);
};

export const canShare = !isClient() ? false : !!navigator.canShare;
export const openLink = (props) => window.open(props);

export const inIframe = () => {
  try {
    return window.self !== window.top;
  } catch (e) {
    return true;
  }
};
