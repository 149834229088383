import React, { CSSProperties, forwardRef, ReactNode } from 'react';
import styled, { css } from 'styled-components';
import Link from 'src/components/Link';

const styles = css`
  appearance: none;
  background: none;
  border: none;
  cursor: pointer;
  flex-shrink: 0;
  font-family: var(--ff-base);
  font-size: ${(p) => (p.large ? 'var(--fs-b1)' : p.small ? 'var(--fs-b3)' : 'var(--fs-b2)')};
  opacity: ${(p) => (p.disabled ? 0.35 : 1)};
  padding: 0;
  transition: 0.35s;
  &:focus {
    outline: none;
  }

  ${({ variant }) =>
    variant === 'primary' &&
    css`
      height: ${(p) => (p.large || p.small ? 'auto' : '48px')};
      background-color: ${(p) => (p.color ? p.color : 'var(--c-fg-0)')};
      border-radius: 999px;
      color: ${(p) => (p.textColor ? p.textColor : 'var(--c-bg-0)')} !important;
      font-weight: 500;
      padding: ${(p) => (p.large ? '16px 28px' : p.small ? '12px 20px' : '14px 24px')};
      user-select: none;
      @supports (-webkit-backdrop-filter: none) {
        backdrop-filter: blur(25px);
      }
      @media (max-width: 768px) {
        ${(p) => p.smallMobile && `padding: 12px 20px; font-size:var(--fs-b3)`}
      }
    `}

  ${({ variant }) =>
    variant === 'text' &&
    css`
      text-align: left;
      color: var(--c-fg-0);
    `}
`;

const SButton = styled.button`
  ${styles}
  line-height: 1;
`;
const SLink = styled(Link)`
  ${styles}
  display: inline-block;
  // override Section a styling
  font-weight: 500 !important;
`;

interface ButtonProps {
  bouncy?: boolean;
  children: ReactNode;
  color?: string;
  disabled?: boolean;
  hex?: string;
  large?: boolean;
  onPress?: () => void;
  small?: boolean;
  smallMobile?: boolean;
  style?: CSSProperties;
  textColor?: string;
  to?: string;
  type?: 'primary' | unknown;
  variant?: 'primary' | 'text';
}

const Button = forwardRef<HTMLButtonElement | HTMLAnchorElement, ButtonProps>(
  (
    {
      children,
      color,
      type = 'primary',
      onPress,
      bouncy,
      large,
      hex,
      textColor,
      small,
      smallMobile,
      disabled,
      style,
      to,
      variant = 'primary',
    }: ButtonProps,
    ref,
  ) => {
    const commonProps = {
      className: 'button pressable',
      color,
      disabled,
      large,
      small,
      smallMobile,
      textColor,
      variant,
    };

    if (to) {
      return (
        <SLink {...commonProps} to={to} ref={ref}>
          {children}
        </SLink>
      );
    }

    return (
      <SButton
        {...commonProps}
        bouncy={bouncy}
        onClick={onPress}
        hex={hex}
        type={type}
        style={style}
        ref={ref}
      >
        {children}
      </SButton>
    );
  },
);

Button.displayName = 'Button';
export default Button;
