import React, { useEffect, useRef, useState } from 'react';
import styled from 'styled-components';
import Button from './Button';
import { ReactComponent as Right } from '../iconic/arrow-right.svg';

const FieldContainer = styled.div`
  --input-height: 64px;
  --input-btn-margin: 8px;
  --input-padding: 24px;
  --input-radius: 1000px;
`;

const InputContainer = styled.div`
  position: relative;
  max-width: 450px;

  button {
    position: absolute;
    margin: var(--input-btn-margin);
    right: 0;
    top: 0;
    bottom: 0;
    display: flex;
    align-items: center;
    justify-content: center;

    span {
      padding-right: 8px;
    }

    &:hover:not[disabled] {
      background-color: var(--c-cerise-0);
    }

    &[disabled] {
      opacity: 0.5;
    }
  }
`;

// Styled input field
const StyledInput = styled.input`
  background: var(--bgc);
  height: 64px;

  font-weight: 500;
  font-size: var(--fs-b1);
  font-family: var(--ff-headline);
  font-feature-settings: 'tnum';
  color: var(--c-fg-0);

  width: 100%;
  padding-left: var(--input-padding);
  padding-right: calc(${(p) => p.$buttonWidth}px + var(--input-btn-margin) * 2);

  border: 1px solid ${(p) => (p.$hasError ? 'var(--c-red-0)' : 'var(--c-input-border)')};
  border-radius: var(--input-radius);
  box-shadow: var(--shadow-input);

  &:focus {
    box-shadow: inset 0 0 1px var(--fg), var(--shadow-input);
  }
`;

const HelpText = styled.span`
  font-size: 14.5px;
  font-weight: 400;
  opacity: 0.75;
  padding: 8px 12px;
  text-align: left;
  display: block;
  height: 34px; // ensures content doesnt jump
  color: var(--c-fg-0);
`;

export interface InputProps {
  submitting: boolean;
  required?: boolean;
  name: string;
  placeholder: string;
  value: string;
  pattern?: string;
  keyboard?: string;
  autocomplete?: string;
  onChange: (val: string) => void;
  onBlur: () => void;
  cta: string;
  error?: string;
  help?: string;
}

export function Input({
  submitting,
  name,
  placeholder,
  value,
  required,
  pattern,
  keyboard,
  autocomplete,
  onChange,
  onBlur,
  help,
  error,
  cta,
}: InputProps) {
  const buttonRef = useRef<HTMLButtonElement | null>(null);
  const [buttonWidth, setButtonWidth] = useState(150);

  useEffect(() => {
    if (buttonRef.current) {
      setButtonWidth(buttonRef.current.offsetWidth);
    }
  }, []);

  return (
    <FieldContainer>
      <InputContainer>
        <StyledInput
          type="text"
          required={required}
          name={name}
          placeholder={placeholder}
          pattern={pattern}
          keyboard={keyboard}
          autocomplete={autocomplete}
          value={value}
          onBlur={onBlur}
          onChange={(event) => onChange(event.target.value)}
          $buttonWidth={buttonWidth}
          $hasError={!!error}
        />
        <Button ref={buttonRef} small disabled={submitting}>
          <span>{submitting ? 'Loading...' : cta}</span>
          <Right stroke="var(--c-bg-0)" />
        </Button>
      </InputContainer>
      <HelpText>{error || help}</HelpText>
    </FieldContainer>
  );
}
