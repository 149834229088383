import React from 'react';
import styled from 'styled-components';

import { SourceLogo } from 'src/components';

const sourceList = [
  'toast',
  'billcom',
  'stripe',
  'upwork',
  'etsy',
  'amazon',
  'airbnb',
  'venmo',
  'uber',
  'gusto',
  'doordash',
  'intuit',
  'taskrabbit',
  'paypal',
  'fiverr',
  'cash app',
  'aisleplanner',
  'grubhub',
  'visa',
  'lyft',
];

const LogoList = styled.div`
  display: flex;
  align-items: flex-start;
  justify-content: center;
  flex-wrap: wrap;
  > div {
    flex-shrink: 0;
    margin: 8px;
  }
`;

const IncomeSources = () => {
  return (
    <LogoList>
      {sourceList?.map((source) => (
        <SourceLogo key={source} source={source} />
      ))}
    </LogoList>
  );
};

export default IncomeSources;
