import React from 'react';
import styled from 'styled-components';

const SSectionTitle = styled.div`
  margin: 0 ${(p) => (p.center ? 'auto' : '0px')} 2rem;
  max-width: var(--width-read);
  h1 {
    font-family: ${(p) => (p.creatour ? 'var(--ff-creatour)' : '')};
    margin-bottom: 24px;
    text-wrap: balance;
  }
  h2 {
    margin-top: 0;
    margin-bottom: 24px;
    text-wrap: balance;
  }
  h3 {
    opacity: 0.75;
    margin: 0;
  }
  b1 {
    font-weight: 400;
    opacity: 0.9;
  }
  * {
    text-align: ${(p) => (p.center ? 'center' : '')};
  }
`;

const SectionTitle = ({ title, subtitle, pretitle, center, large, color, promo, creatour }) => {
  return (
    <SSectionTitle center={center} creatour={creatour}>
      {!!pretitle && <b1 style={{ marginBottom: 24 }}>{pretitle}</b1>}
      {!!large ? (
        <>
          <h1 className={!!color && 'mask-gradient'} style={{ backgroundImage: color }}>
            {title}
          </h1>
          <h3 className="r">{subtitle}</h3>
        </>
      ) : (
        <>
          <h2 className={!!color && 'mask-gradient'} style={{ backgroundImage: color }}>
            {title}
          </h2>
          <b1 className="r">{subtitle}</b1>
        </>
      )}

      {!!promo ? (
        <b2 className="o50">
          <br />
          {promo}
        </b2>
      ) : null}
    </SSectionTitle>
  );
};

export default SectionTitle;
