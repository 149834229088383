import { DEFAULT_DESCRIPTION } from 'src/data/meta';
import { FAQ } from './index';
import { KEYWORDS } from 'src/data/meta';

type MetaSearchFeatureType =
  | 'Article'
  | 'Breadcrumb'
  | 'FAQ'
  | 'HowTo'
  | 'Video'
  | 'Website'
  | 'Webpage'
  | 'Organization';

type MetaOgPageType = 'article' | 'profile' | 'website' | 'video';

type MetaPageNodeType =
  | 'Home'
  | 'BlogPost'
  | 'GuidePost'
  | 'CaseStudy'
  | 'Interview'
  | 'Feature'
  | 'Other';

export type PrefetchApplicationType = 'APP' | 'PX' | 'SITE' | 'APP_RENDER';

export interface HowToStep {
  name: string;
  url: string;
  image: string;
  text: string;
}

export interface HowToSteps {
  name: string;
  image: string;
  minutes: number; // P0DT10M
  steps: Array<HowToStep>;
}

export interface TypeSet {
  og: MetaOgPageType;
  jsonld: MetaSearchFeatureType;
}

export const constructMetaTags = (metadata: PageMetadata) => {
  var array = [];

  if (metadata.video) array.push(ldVideo(metadata));
  if (!metadata.video && /BlogPost|GuidePost|CaseStudy|Interview/.test(metadata.pageType))
    array.push(ldArticle(metadata));
  if (metadata.faqs) array.push(ldFaq(metadata));
  if (metadata.steps) array.push(ldHowTo(metadata));
  if (metadata.breadcrumbs) array.push(ldBreadcrumb(metadata.breadcrumbs));

  array.push(ldWebpage(metadata));
  array.push(ldWebsite(metadata));

  return array;
};

export interface PageMetadata {
  title: string;
  description: string;
  keywords: Array<string>;
  pageType: MetaPageNodeType;
  faqs: Array<FAQ>;
  datePublished: string;
  dateModified: string;
  tags: Array<string>;
  data: Array<{ label: string; value: string }>; // max 2
  image: string; // url
  canonical: string; // url
  path: string; // canonical without domain
  video: string; // url
  people: Array<{ firstName: string; lastName: string; jobTitle: string }>;
  steps: HowToSteps;
  breadcrumbs: Array<{ name: string; url: string }>;
  hideAppSuggestion?: boolean;
}

/**
 *
 *
 * STATICS
 *
 *
 */

const ldOrganization = () => ({
  '@context': 'https://schema.org',
  '@type': 'Organization',
  name: 'Catch',
  legalName: 'Catch Financial, Inc.',
  email: 'hey@catch.co',
  logo: '',
});

const ldWebsite = (metadata: PageMetadata) => ({
  '@context': 'https://schema.org',
  '@type': 'WebSite',
  name: 'Catch',
  description: DEFAULT_DESCRIPTION,
  countryOfOrigin: 'us',
  keywords: KEYWORDS.Catch, // fallback keywords
  image: 'default', // fallback og image
  creator: ldOrganization(metadata),
});

const ldWebpage = (metadata: PageMetadata) => ({
  '@context': 'https://schema.org',
  '@type': 'WebPage',
  name: metadata.title,
  description: metadata.description,
  image: metadata.image,
  keywords: metadata.keywords?.join(','),
  url: metadata.canonical,
  creator: ldOrganization(metadata),
  isPartOf: ldWebsite(metadata),
});

const ldArticle = (metadata: PageMetadata) => ({
  '@context': 'https://schema.org',
  '@type': 'NewsArticle',
  headline: metadata.title,
  image: [metadata.image],
  datePublished: metadata.datePublished,
  dateModified: metadata.dateModified,
  publisher: ldOrganization(metadata),
  mainEntityOfPage: ldWebpage(metadata),
  author: metadata.people?.map((person) => ({
    '@type': 'Person',
    givenName: person?.firstName,
    familyName: person?.lastName,
    jobTitle: person?.jobTitle,
  })),
});

const ldFaq = (metadata: PageMetadata) => ({
  '@context': 'https://schema.org',
  '@type': 'FAQPage',
  name: metadata.title,
  isPartOf: ldWebsite(metadata),
  mainConentOfPage: ldWebpage(metadata),
  mainEntity: metadata.faqs?.map((faq) => ({
    '@type': 'Question',
    name: faq.title,
    acceptedAnswer: {
      '@type': 'Answer',
      text: faq.explanation,
    },
  })),
});

const ldHowTo = (metadata: PageMetadata) => ({
  '@context': 'https://schema.org',
  '@type': 'HowTo',
  image: metadata.steps.image
    ? {
        '@type': 'ImageObject',
        url: metadata.steps.image,
      }
    : undefined,
  name: metadata.steps.name,
  totalTime: `PT${metadata.steps.minutes}M`,
  step: metadata.steps.steps.map((step) => ({
    '@type': 'HowToStep',
    name: step.name,
    text: step.text || step.name,
    image: step.image,
    url: step.url,
  })),
});

const ldBreadcrumb = (breadcrumbs: Array<{ name: string; url: string }>) => ({
  '@context': 'https://schema.org',
  '@type': 'BreadcrumbList',
  itemListElement: breadcrumbs?.map((crumb, idx) => ({
    '@type': 'ListItem',
    position: idx + 1,
    name: crumb.name,
    item: crumb.url,
  })),
});

const ldVideo = (metadata: PageMetadata) => ({
  '@context': 'https://schema.org',
  '@type': 'VideoObject',
  name: metadata.title,
  description: metadata.description,
  thumbnailUrl: [metadata.image],
  uploadDate: metadata.datePublished,
  contentUrl: metadata.video,
  regionsAllowed: 'US',
  isPartOf: ldArticle(metadata),
});
