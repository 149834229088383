import React, { useContext } from 'react';
import styled from 'styled-components';
import { usePartner } from 'src/hooks/usePartner';
import { SessionContext } from 'src/context/SessionContext';
import { readableColor } from 'polished';

interface FilterPillProps {
  text: string;
  name?: string;
  active?: boolean;
  onPress(text: any): any;
}

export const FilterPill = ({ onPress, text, name, icon, active }: FilterPillProps) => {
  const { partner } = useContext(SessionContext);
  const { color } = usePartner({ slug: partner });
  return (
    <SPill
      className="pressable"
      active={active}
      onClick={() => onPress(name || text)}
      color={color}
    >
      {icon &&
        React.createElement(icon, {
          stroke: active ? 'var(--c-bg-0)' : 'var(--c-fg-0)',
          height: 20,
        })}
      {active === 0 && !!text ? '+' : active} {text}
    </SPill>
  );
};

const SPill = styled.div`
  border-radius: 999px;
  padding: 0 16px;
  margin: 0 8px 0 0;
  height: 40px;
  display: flex;
  align-items: center;
  flex-shrink: 0;
  line-height: 1;
  color: ${(p) => (p.active ? (false ? readableColor(p.color) : 'var(--bg)') : 'var(--c-fg-0)')};
  background: ${(p) => (p.active ? 'var(--c-fg-0)' : 'var(--c-border-light)')};
  font-size: var(--fs-b3);
  font-weight: 500;
  opacity: ${(p) => (p.disabled ? 0.25 : 1)};
  cursor: pointer;
  font-variant-numeric: tabular-nums;
  backdrop-filter: blur(40px);

  &:hover {
    background: ${(p) => (p.active ? 'var(--c-fg-1)' : 'var(--c-border)')};
  }
`;

export default FilterPill;
