import React from 'react';
import styled from 'styled-components';
import { Button, Container, Section } from '.';
import SectionTitle from './SectionTitle';

const Base = styled.div`
  text-align: center;
  width: 100%;
  box-sizing: border-box;
  color: var(--c-fg-0);
  background: ${(p) => (p.bg ? p.bg : 'var(--c-bg-0)')};
  padding: calc(
      ${(p) => (p.small ? 0.5 : 1)} * var(--padding-section) + var(--height-nav-sm) +
        ${(p) => (p.small ? 'var(--height-nav)' : '0px')}
    )
    var(--side-margin) calc(${(p) => (p.small ? 0.5 : 1)} * var(--padding-section));
`;

const SSplitHero = styled.div`
  width: 100%;
  box-sizing: border-box;
  @media (max-width: 800px) {
    padding-top: calc(0.5 * var(--s-nav-height)) !important;
  }
`;

const SHeroSection = styled(Base)`
  text-align: center;
  width: 100%;
  box-sizing: border-box;
  background: ${(p) => (p.bg ? p.bg : 'var(--c-bg-0)')};
  padding: calc(
      ${(p) => (p.small ? 0.5 : 1)} * var(--padding-section) +
        var(--height-nav-sm) + ${(p) => (p.small ? 'var(--height-nav)' : '0px')}
    )
    var(--side-margin)
    calc(${(p) => (p.small ? 0.5 : 1)} * var(--padding-section));
  );
`;

const SSplit = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  z-index: 1;
  position: relative;
  > div:last-child {
    width: 35%;
    padding-left: var(--side-margin);
    flex-grow: 0;
  }
  > div:first-child {
    width: 60%;
    flex-grow: 0;
  }
  @media (max-width: 800px) {
    display: block;
    > div {
      width: 100% !important;
      padding: 0 !important;
    }
  }
`;

const SImg = styled.div`
  filter: invert(100%) saturate(0) brightness(500%);
  height: 38px !important;
  width: 160px !important;
  margin: 0 !important;
  display: flex;
  align-self: flex-start !important;
  background: url(${(p) => p.src}) left/contain no-repeat;
  @media (prefers-color-scheme: light) {
    filter: none;
  }
`;

const DEF = 'Title';

const Hero = ({
  title = DEF,
  subtitle,
  button,
  color,
  type = 'subtle',
  small,
  home,
  children,
  preComponent,
}) => {
  return (
    <SHeroSection
      home={home}
      className="hero"
      template={title === DEF}
      color={color}
      type={type}
      small={small}
    >
      <Container narrow>
        <SectionTitle title={title} subtitle={subtitle} pretitle={preComponent} large={!small} />
        {!!button && (
          <div className="pad-y">
            <Button>{button?.text}</Button>
          </div>
        )}
        {!!children && <div className="pad-y">{children}</div>}
      </Container>
    </SHeroSection>
  );
};

export const SplitHero = ({
  title = 'Save thousands on health insurance',
  subtitle = 'The fastest way to compare hundreds of health and dental plans. Free support from licensed agents. Savings automatically applied.',
  children,
  logo,
  promo,
  accessory,
  eb,
}) => {
  return (
    <SSplitHero>
      <Section small noFade>
        <div className="pad-y2">
          <SSplit>
            <div>
              {!!logo && <SImg src={logo} />}
              <SectionTitle large title={title} subtitle={subtitle} promo={promo} pretitle={eb} />
              <div className="pad-y2">{children}</div>
            </div>
            <div>{accessory}</div>
          </SSplit>
        </div>
      </Section>
    </SSplitHero>
  );
};

export default Hero;
