import React from 'react';
import styled from 'styled-components';

const SContainer = styled.div`
  width: 100%;
  > div {
    width: 100%;
    max-width: ${(p) =>
      p.full
        ? '100%'
        : p.ultranarrow
        ? 'var(--width-block)'
        : p.narrow
        ? 'var(--width-read)'
        : 'var(--width-nav)'};
    margin-left: auto;
    margin-right: auto;
  }
  @media (max-width: 800px) {
    > div {
      max-width: 100% !important;
    }
  }
`;

const Container = ({ children, narrow, ultranarrow, full }) => {
  return (
    <SContainer narrow={narrow} ultranarrow={ultranarrow} full={full}>
      <div className="container">{children}</div>
    </SContainer>
  );
};

export default Container;
