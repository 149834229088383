import React from 'react';
import { SkeletonTheme } from 'react-loading-skeleton';
import { QueryParamProvider } from 'use-query-params';
import { env } from 'src/env';
import { LogRocket } from 'src/lib';
import { IframeResizer } from 'src/components';

import { SessionProvider } from './src/context/SessionContext';
import { LocationProvider } from '@reach/router';

import './src/style/index.css';
import './src/style/old/index.scss';
import 'react-loading-skeleton/dist/skeleton.css';

String.prototype.toProperCase = function () {
  return `${this}`?.toLowerCase() === 'identity'
    ? this.charAt(0).toUpperCase() + this.substr(1).toLowerCase()
    : this.toUpperCase()
        .replaceAll('_', ' ')
        .replaceAll(
          /(?!KYC|CIP|IRA|DDA|DMI|SVI|OEP|SEP|MEC|APTC|CSR|CSRS|EDE|ID|MSCI|ETF|SIPC|FDIC|NONESCMEC|ACC|RF1|RF2|RF3|RF4\b)\b\w+\S*/g,
          function (txt) {
            return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase();
          },
        );
};

export const onClientEntry = () => {
  if (env.isProd) {
    LogRocket.init();
  }
};

export const wrapRootElement = ({ element }) => {
  if (window && window.location.host === 'health.catch.co') {
    window.open(`https://catch.co/benefits/health${window.location.search}`, '_self');
  }

  return (
    <IframeResizer>
      <QueryParamProvider>
        <SkeletonTheme baseColor="var(--fgaa)" highlightColor="var(--c-border)">
          <LocationProvider>
            <SessionProvider>{element}</SessionProvider>
          </LocationProvider>
        </SkeletonTheme>
      </QueryParamProvider>
    </IframeResizer>
  );
};
