import { properCase } from './string';

import { ReactComponent as Pill } from 'src/iconic/pills.svg';
import { ReactComponent as Doctor } from 'src/iconic/stethoscope.svg';
import { ReactComponent as Vision } from 'src/iconic/spectacles.svg';
import { ReactComponent as Medical } from 'src/iconic/medical.svg';
import { ReactComponent as Lab } from 'src/iconic/search-area.svg';
import { $t } from './translate';

export const DISPLAY = 3;

export const metalColors = {
  catastrophic: '#E65251',
  silver: '#C2D0D8',
  bronze: '#DFB883',
  'expanded bronze': '#DFB883',
  gold: '#FFD612',
  platinum: '#E0DCD3',
};

export const metalLabels = {
  Catastrophic: $t({ id: 'health-plan.catastrophic', defaultMessage: 'Catastrophic' }),
  Bronze: $t({ id: 'health-plan.bronze', defaultMessage: 'Bronze' }),
  'Expanded Bronze': $t({ id: 'health-plan.expanded-bronze', defaultMessage: 'Expanded Bronze' }),
  Silver: $t({ id: 'health-plan.silver', defaultMessage: 'Silver' }),
  Gold: $t({ id: 'health-plan.gold', defaultMessage: 'Gold' }),
  Platinum: $t({ id: 'health-plan.platinum', defaultMessage: 'Platinum' }),
};

export const metalAv = {
  catastrophic: 0.2,
  silver: 0.5,
  bronze: 0.4,
  'expanded bronze': 0.45,
  gold: 0.6,
  platinum: 0.7,
};

export const sortItems = (a, b) => {
  return itemWeights[b.label] - itemWeights[a.label];
};
export const offlineEnrollmentStates = [];
export const oscarStates = [];
export const ffmStates = [
  'AL',
  'AK',
  'AZ',
  'AR',
  'DE',
  'FL',
  'GA',
  'HI',
  'IL',
  'IN',
  'IA',
  'KS',
  'LA',
  'MI',
  'MO',
  'MS',
  'MT',
  'NE',
  'NH',
  'NC',
  'ND',
  'OH',
  'OK',
  'OR',
  'SC',
  'SD',
  'TN',
  'TX',
  'UT',
  'WV',
  'WI',
  'WY',
];

export const getPremium = (plan, aptc) => {
  return Math.max(0, plan?.premium - aptc);
};

export const getDeductible = (plan) => {
  return plan?.deductibles?.[0]?.amount;
};

export const DISPLAY_RATE = 0.95;

export const copy = {};
export const tooltips = {};
export const itemWeights = {
  // doctor visits
  PREVENTIVE_CARE: 3,
  PRIMARY_VISIT: 2,
  SPECIALIST_VISIT: 1,
  // Prescription drugs
  GENERIC_DRUGS: 4,
  PREFERRED_DRUGS: 3,
  NON_PREFERRED_DRUGS: 2,
  SPECIALTY_DRUGS: 1,
  // Treatment and services
  XRAYS_DIAGNOSTIC: 9,
  IMAGING: 8,
  OUTPATIENT_LAB: 7,
  OUTPATIENT_REHAB: 6,
  OUTPATIENT_SURGERY: 5,
  INPATIENT_SURGICAL: 4,
  INPATIENT_HOSPITAL: 3,
  OUTPATIENT_MENTAL: 2,
  INPATIENT_MENTAL: 1,
  // Vision and dental
  ROUTINE_EYE_ADULT: 5,
  ROUTINE_DENTAL_ADULT: 4,
  BASIC_DENTAL_ADULT: 3,
  MAJOR_DENTAL_ADULT: 2,
  ORTHO_ADULT: 1,
};

export const freeCareDefaults = [
  copy['VACCINATION'],
  copy['STI'],
  copy['HIV'],
  copy['BLOOD_PRESSURE'],
  copy['CONTRACEPTION'],
  copy['CER_CANCER_SCREEN'],
  copy['BRE_CANCER_SCREEN'],
  copy['ALCOHOL_SCREEN'],
  copy['TOBACCO_SCREEN'],
];
export const familyDefaults = [
  copy['CONTRACEPTION'],
  copy['STI'],
  copy['HIV'],
  copy['VACCINATION'],
  copy['CHILD_BEHAVIOR'],
  copy['CHILD_DEVELOP'],
];

export const SHORT_NAMES = {
  'Primary Care Visit to Treat an Injury or Illness': $t({
    id: 'health-plan.primary-short',
    defaultMessage: 'Primary care visit',
  }),
  'Preventive Care/Screening/Immunization': $t({
    id: 'health-plan.preventative-short',
    defaultMessage: 'Preventative care',
  }),
  'Specialist Visit': $t({
    id: 'health-plan.specialist-short',
    defaultMessage: 'Specialist visit',
  }),
  'Generic Drugs': $t({
    id: 'health-plan.generic-short',
    defaultMessage: 'Generic drugs',
  }),
};

// Doctor visits
export const PREVENTIVE_CARE = 'Preventive Care/Screening/Immunization';
export const PRIMARY_VISIT = 'Primary Care Visit to Treat an Injury or Illness';
export const SPECIALIST_VISIT = 'Specialist Visit';

// Prescriptions
export const GENERIC_DRUGS = 'Generic Drugs';
export const PREFERRED_DRUGS = 'Preferred Brand Drugs';
export const NON_PREFERRED_DRUGS = 'Non-Preferred Brand Drugs';
export const SPECIALTY_DRUGS = 'Specialty Drugs';

// Vision and dental
export const ROUTINE_EYE_ADULT = 'Routine Eye Exam (Adult)';
export const ROUTINE_DENTAL_ADULT = 'Routine Dental Services (Adult)';
export const BASIC_DENTAL_ADULT = 'Basic Dental Care - Adult';
export const MAJOR_DENTAL_ADULT = 'Major Dental Care - Adult';
export const ORTHO_ADULT = 'Orthodontia - Adult';
// Children and family planning
export const INFERTILITY = 'Infertility Treatment';
export const MAJOR_DENTAL_CHILD = 'Major Dental Care - Child';
export const ORTHO_CHILD = 'Orthodontia - Child';
export const BASIC_DENTAL_CHILD = 'Basic Dental Care - Child';
export const ROUTINE_EYE_CHILD = 'Routine Eye Exam for Children';
export const GLASSES_CHILD = 'Eye Glasses for Children';
export const DENTAL_CHILD = 'Dental Check-Up for Children';
// General treatment and services
export const AMBULANCE = 'Emergency Transportation/Ambulance';
export const ER_SERVICES = 'Emergency Room Services';
export const INPATIENT_MENTAL = 'Mental/Behavioral Health Inpatient Services';
export const INPATIENT_HOSPITAL = 'Inpatient Hospital Services (e.g., Hospital Stay)';
export const INPATIENT_SURGICAL = 'Inpatient Physician and Surgical Services';
export const OUTPATIENT_MENTAL = 'Mental/Behavioral Health Outpatient Services';
export const OUTPATIENT_REHAB = 'Outpatient Rehabilitation Services';
export const OUTPATIENT_FACILITY = 'Outpatient Facility Fee (e.g.,  Ambulatory Surgery Center)';
export const OUTPATIENT_SURGERY = 'Outpatient Surgery Physician/Surgical Services';
export const HABIL_SERVICES = 'Habilitation Services';
export const SKILLED_NURSING = 'Skilled Nursing Facility';
export const PRIVATE_NURSING = 'Private-Duty Nursing';
// Surgeries and procedures
export const HEARING_AIDS = 'Hearing Aids';
export const BARIATRIC_SURGERY = 'Bariatric Surgery';
export const MED_EQUIPMENT = 'Durable Medical Equipment';
export const CHEMO = 'Chemotherapy';
export const DIALYSIS = 'Dialysis';
export const CHIRO_CARE = 'Chiropractic Care';
export const ACUPUNCTURE = 'Acupuncture';
// Lab work
export const IMAGING = 'Imaging (CT/PET Scans, MRIs)';
export const OUTPATIENT_LAB = 'Laboratory Outpatient and Professional Services';
export const XRAYS_DIAGNOSTIC = 'X-rays and Diagnostic Imaging';

const labels = {
  // Doctor visits
  [PREVENTIVE_CARE]: $t({
    id: 'health-plan.preventative-care',
    defaultMessage: 'Preventive Care/Screening/Immunization',
  }),
  [PRIMARY_VISIT]: $t({
    id: 'health-plan.primary-visit',
    defaultMessage: 'Primary Care Visit to Treat an Injury or Illness',
  }),
  [SPECIALIST_VISIT]: $t({
    id: 'health-plan.specialist-visit',
    defaultMessage: 'Specialist Visit',
  }),

  // Prescriptions
  [GENERIC_DRUGS]: $t({ id: 'health-plan.generic-drugs', defaultMessage: 'Generic Drugs' }),
  [PREFERRED_DRUGS]: $t({
    id: 'health-plan.preferred-drugs',
    defaultMessage: 'Preferred Brand Drugs',
  }),
  [NON_PREFERRED_DRUGS]: $t({
    id: 'health-plan.non-preferred-drugs',
    defaultMessage: 'Non-Preferred Brand Drugs',
  }),
  [SPECIALTY_DRUGS]: $t({ id: 'health-plan.speciality-drugs', defaultMessage: 'Specialty Drugs' }),

  // Vision and dental
  [ROUTINE_EYE_ADULT]: $t({
    id: 'health-plan.routine-eye',
    defaultMessage: 'Routine Eye Exam (Adult)',
  }),
  [ROUTINE_DENTAL_ADULT]: $t({
    id: 'health-plan.routine-dental',
    defaultMessage: 'Routine Dental Services (Adult)',
  }),
  [BASIC_DENTAL_ADULT]: $t({
    id: 'health-plan.basic-dental',
    defaultMessage: 'Basic Dental Care - Adult',
  }),
  [MAJOR_DENTAL_ADULT]: $t({
    id: 'health-plan.major-dental',
    defaultMessage: 'Major Dental Care - Adult',
  }),
  [ORTHO_ADULT]: $t({ id: 'health-plan.ortho', defaultMessage: 'Orthodontia - Adult' }),

  // Children and family planning
  [INFERTILITY]: $t({ id: 'health-plan.infertility', defaultMessage: 'Infertility Treatment' }),
  [MAJOR_DENTAL_CHILD]: $t({
    id: 'health-plan.marjor-dental-child',
    defaultMessage: 'Major Dental Care - Child',
  }),
  [ORTHO_CHILD]: $t({ id: 'health-plan.ortho-child', defaultMessage: 'Orthodontia - Child' }),
  [BASIC_DENTAL_CHILD]: $t({
    id: 'health-plan.basic-dental-child',
    defaultMessage: 'Basic Dental Care - Child',
  }),
  [ROUTINE_EYE_CHILD]: $t({
    id: 'health-plan.routine-eye-child',
    defaultMessage: 'Routine Eye Exam for Children',
  }),
  [GLASSES_CHILD]: $t({
    id: 'health-plan.glasses-child',
    defaultMessage: 'Eye Glasses for Children',
  }),
  [DENTAL_CHILD]: $t({
    id: 'health-plan.dental-child',
    defaultMessage: 'Dental Check-Up for Children',
  }),

  // General treatment and services
  [AMBULANCE]: $t({
    id: 'health-plan.ambulance',
    defaultMessage: 'Emergency Transportation/Ambulance',
  }),
  [ER_SERVICES]: $t({ id: 'health-plan.er-services', defaultMessage: 'Emergency Room Services' }),
  [INPATIENT_MENTAL]: $t({
    id: 'health-plan.inpatient-mental',
    defaultMessage: 'Mental/Behavioral Health Inpatient Services',
  }),
  [INPATIENT_HOSPITAL]: $t({
    id: 'health-plan.inpatient-hospital',
    defaultMessage: 'Inpatient Hospital Services (e.g., Hospital Stay)',
  }),
  [INPATIENT_SURGICAL]: $t({
    id: 'health-plan.inpatient-surgical',
    defaultMessage: 'Inpatient Physician and Surgical Services',
  }),
  [OUTPATIENT_MENTAL]: $t({
    id: 'health-plan.outpatient-mental',
    defaultMessage: 'Mental/Behavioral Health Outpatient Services',
  }),
  [OUTPATIENT_REHAB]: $t({
    id: 'health-plan.outpatient-rehab',
    defaultMessage: 'Outpatient Rehabilitation Services',
  }),
  [OUTPATIENT_FACILITY]: $t({
    id: 'health-plan.outpatient-facility',
    defaultMessage: 'Outpatient Facility Fee (e.g.,  Ambulatory Surgery Center)',
  }),
  [OUTPATIENT_SURGERY]: $t({
    id: 'health-plan.outpatient-surgery',
    defaultMessage: 'Outpatient Surgery Physician/Surgical Services',
  }),
  [HABIL_SERVICES]: $t({
    id: 'health-plan.habil-services',
    defaultMessage: 'Habilitation Services',
  }),
  [SKILLED_NURSING]: $t({
    id: 'health-plan.skilled-nursing',
    defaultMessage: 'Skilled Nursing Facility',
  }),
  [PRIVATE_NURSING]: $t({
    id: 'health-plan.private-nursing',
    defaultMessage: 'Private-Duty Nursing',
  }),

  // Surgeries and procedures
  [HEARING_AIDS]: $t({ id: 'health-plan.hearing-aids', defaultMessage: 'Hearing Aids' }),
  [BARIATRIC_SURGERY]: $t({
    id: 'health-plan.bariatric-surgery',
    defaultMessage: 'Bariatric Surgery',
  }),
  [MED_EQUIPMENT]: $t({
    id: 'health-plan.med-equipment',
    defaultMessage: 'Durable Medical Equipment',
  }),
  [CHEMO]: $t({ id: 'health-plan.chemo', defaultMessage: 'Chemotherapy' }),
  [DIALYSIS]: $t({ id: 'health-plan.dialysis', defaultMessage: 'Dialysis' }),
  [CHIRO_CARE]: $t({ id: 'health-plan.chiro-care', defaultMessage: 'Chiropractic Care' }),
  [ACUPUNCTURE]: $t({ id: 'health-plan.acupuncture', defaultMessage: 'Acupuncture' }),

  // Lab work
  [IMAGING]: $t({ id: 'health-plan.imaging', defaultMessage: 'Imaging (CT/PET Scans, MRIs)' }),
  [OUTPATIENT_LAB]: $t({
    id: 'health-plan.outpatient-lab',
    defaultMessage: 'Laboratory Outpatient and Professional Services',
  }),
  [XRAYS_DIAGNOSTIC]: $t({
    id: 'health-plan.xrays-diagnostic',
    defaultMessage: 'X-rays and Diagnostic Imaging',
  }),
};

export function formatDeductibles(deductibles) {
  if (!deductibles) {
    return { individual: null, family: null };
  }

  let individual = {
    total: null,
    medical: null,
    drug: null,
    combined: null,
  };

  let family = {
    total: null,
    medical: null,
    drug: null,
    combined: null,
  };

  // sets fields for each deductible
  deductibles?.forEach((d) => {
    const obj = d.familyCost === 'Family' ? family : individual;
    const field = /Combined/.test(d.type)
      ? 'combined'
      : /Medical/.test(d.type)
      ? 'medical'
      : 'drug';
    obj[field] = d.amount;
  });

  if (family.combined === null && family.medical === null) {
    family = null;
  } else {
    family.total = family.combined !== null ? family.combined : family.medical + family.drug;
  }

  // either set object to null or compute totals
  const nullifyOrTotal = (obj) => {
    if (!obj || (obj.combined === null && obj.medical === null)) {
      return null;
    } else {
      obj.total = obj.combined !== null ? obj.combined : obj.medical + obj.drug;
      return obj;
    }
  };

  return {
    individual: nullifyOrTotal(individual),
    family: nullifyOrTotal(family),
  };
}

export function formatBenefit(item, category) {
  if (/deductible|Deductible/.test(item.value)) {
    category[1] = [
      ...category[1],
      {
        label: item.label,
        value: 'Full Cost',
        info: item.info,
      },
    ];
    if (/Coinsurance/.test(item.value)) {
      category[2] = [
        ...category[2],
        {
          label: item.label,
          value: item.value,
          info: item.info,
        },
      ];
    } else if (/Copay/.test(item.value)) {
      category[2] = [
        ...category[2],
        {
          label: item.label,
          value: item.value,
          info: item.info,
        },
      ];
    } else if (/No Charge/.test(item.value)) {
      category[2] = [
        ...category[2],
        {
          label: item.label,
          value: $t({ id: 'health-plan.free', defaultMessage: 'Free' }),
          info: item.info,
        },
      ];
    } else {
      category[2] = [...category[2], item];
    }
  } else if (item.value == 'No Charge') {
    category[0] = [
      ...category[0],
      {
        label: item.label,
        value: $t({ id: 'health-plan.free', defaultMessage: 'Free' }),
        info: item.info,
      },
    ];
  } else {
    category[0] = [...category[0], item];
  }
}

export const formatSingleBenefit = (b) => {
  return {
    label: b.name,
    value: b.covered
      ? b?.costSharings?.[0]?.displayString
      : $t({ id: 'health-plan.not-covered', defaultMessage: 'Not covered' }),
    info: b.name,
  };
};

export function formatCostCoverageTop(benefits) {
  const categories = {
    docVisits: [[], []],
  };
  return benefits?.reduce((acc, b) => {
    const payload = {
      label: SHORT_NAMES[b.name],
      value: b.covered
        ? b?.costSharings?.[0]?.displayString
        : $t({ id: 'health-plan.not-covered', defaultMessage: 'Not covered' }),
      info: b.name,
    };

    switch (b.name) {
      case PRIMARY_VISIT:
      case SPECIALIST_VISIT:
      case PREVENTIVE_CARE:
      case GENERIC_DRUGS:
        return {
          ...acc,
          docVisits: formatItemCost(payload, acc.docVisits).sort(sortItems),
        };

      default:
        return acc;
    }
  }, categories);
}

export function formatCostCoverage(benefits) {
  const categories = {
    docVisits: [[], []],
    treatAndServices: [[], []],
    labWork: [[], []],
    drugs: [[], []],

    visionDental: [[], []],
  };

  return benefits?.reduce((acc, b) => {
    const payload = {
      label: b.name,
      value: b.covered
        ? b?.costSharings?.[0]?.displayString
        : $t({ id: 'health-plan.not-covered', defaultMessage: 'Not covered' }),
      info: b.name,
    };
    switch (b.name) {
      case PREVENTIVE_CARE:
      case PRIMARY_VISIT:
      case SPECIALIST_VISIT:
        return {
          ...acc,
          docVisits: formatItemCost(payload, acc.docVisits).sort(sortItems),
        };
      case OUTPATIENT_MENTAL:
      case OUTPATIENT_REHAB:

      case OUTPATIENT_SURGERY:
      case INPATIENT_SURGICAL:
      case INPATIENT_HOSPITAL:
      case INPATIENT_MENTAL:
      case ER_SERVICES:
        return {
          ...acc,
          treatAndServices: formatItemCost(payload, acc.treatAndServices).map((group) =>
            group.sort(sortItems),
          ),
        };
      case XRAYS_DIAGNOSTIC:
      case IMAGING:
      case OUTPATIENT_LAB:
        return {
          ...acc,
          labWork: formatItemCost(payload, acc.labWork).map((group) => group.sort(sortItems)),
        };
      case GENERIC_DRUGS:
      case PREFERRED_DRUGS:
      case NON_PREFERRED_DRUGS:
      case SPECIALTY_DRUGS:
        return {
          ...acc,
          drugs: formatItemCost(payload, acc.drugs).map((group) => group.sort(sortItems)),
        };
      case ROUTINE_EYE_ADULT:
      case ROUTINE_DENTAL_ADULT:
      case BASIC_DENTAL_ADULT:
      case MAJOR_DENTAL_ADULT:
      case ORTHO_ADULT:
        return {
          ...acc,
          visionDental: formatItemCost(payload, acc.visionDental).map((group) =>
            group.sort(sortItems),
          ),
        };
      default:
        return acc;
    }
  }, categories);
}

export function sortBenefits(benefits) {
  const doctorVisits = [[], [], []];
  const prescriptions = [[], [], []];
  const visionAndDental = [[], [], []];
  const familyPlanning = [
    familyDefaults.map((label) => ({
      label,
      value: $t({ id: 'health-plan.free', defaultMessage: 'Free' }),
    })),
    [],
    [],
  ];
  const generalServices = [[], [], []];
  const surgeries = [[], [], []];
  const labWork = [[], [], []];
  const freeCare = [
    freeCareDefaults.map((label) => ({
      label,
      value: $t({ id: 'health-plan.free', defaultMessage: 'Free' }),
    })),
    [],
    [],
  ];
  benefits.forEach((ben) => {
    const payload = {
      label: ben.name,
      value: ben.covered ? ben?.costSharings?.[0]?.displayString : copy['notCovered'],
      info: tooltips[ben.name],
    };
    switch (ben.name) {
      case PREVENTIVE_CARE:
      case PRIMARY_VISIT:
      case SPECIALIST_VISIT:
        formatBenefit(payload, doctorVisits);
        break;
      case GENERIC_DRUGS:
      case PREFERRED_DRUGS:
      case NON_PREFERRED_DRUGS:
      case SPECIALTY_DRUGS:
        formatBenefit(payload, prescriptions);
        break;
      case ROUTINE_EYE_ADULT:
      case ROUTINE_DENTAL_ADULT:
      case BASIC_DENTAL_ADULT:
      case MAJOR_DENTAL_ADULT:
      case ORTHO_ADULT:
        formatBenefit(payload, visionAndDental);
        break;
      case INFERTILITY:
      case MAJOR_DENTAL_CHILD:
      case ORTHO_CHILD:
      case BASIC_DENTAL_CHILD:
      case ROUTINE_EYE_CHILD:
      case GLASSES_CHILD:
      case DENTAL_CHILD:
        formatBenefit(payload, familyPlanning);
        break;
      case AMBULANCE:
      case ER_SERVICES:
      case INPATIENT_MENTAL:
      case INPATIENT_HOSPITAL:
      case INPATIENT_SURGICAL:
      case OUTPATIENT_MENTAL:
      case OUTPATIENT_REHAB:
      case OUTPATIENT_FACILITY:
      case OUTPATIENT_SURGERY:
      case HABIL_SERVICES:
      case SKILLED_NURSING:
      case PRIVATE_NURSING:
        formatBenefit(payload, generalServices);
        break;
      case HEARING_AIDS:
      case BARIATRIC_SURGERY:
      case MED_EQUIPMENT:
      case CHEMO:
      case DIALYSIS:
      case CHIRO_CARE:
      case ACUPUNCTURE:
        formatBenefit(payload, surgeries);
        break;
      case IMAGING:
      case OUTPATIENT_LAB:
      case XRAYS_DIAGNOSTIC:
        formatBenefit(payload, labWork);
        break;
    }
  });

  return {
    doctorVisits,
    prescriptions,
    visionAndDental,
    familyPlanning,
    generalServices,
    surgeries,
    labWork,
    freeCare,
  };
}

export function formatItemCost(item, category) {
  let preDeductible;
  let postDeductible;

  item.label = labels[item.label] || item.label;

  if (/deductible|Deductible/.test(item.value)) {
    preDeductible = [
      ...category[0],
      {
        label: item.label,
        value: `100% ${$t({ id: 'health-plan.cost', defaultMessage: 'cost' })}`,
        info: item.info,
      },
    ];
    if (/Coinsurance/.test(item.value)) {
      postDeductible = [
        ...category[1],
        {
          label: item.label,
          value: `${item.value.split(' ')[0]} ${$t({
            id: 'health-plan.cost',
            defaultMessage: 'cost',
          })}`,
          info: item.info,
        },
      ];
    } else if (/Copay/.test(item.value)) {
      postDeductible = [
        ...category[1],
        {
          label: item.label,
          value: item.value.split(' ')[0],
          info: item.info,
        },
      ];
    } else if (/No Charge/.test(item.value)) {
      postDeductible = [
        ...category[1],
        {
          label: item.label,
          value: $t({ id: 'health-plan.free', defaultMessage: 'Free' }),
          info: item.info,
        },
      ];
    } else {
      postDeductible = [...category[1], item];
    }
  } else if (item.value == 'No Charge') {
    preDeductible = [
      ...category[0],
      {
        label: item.label,
        value: $t({ id: 'health-plan.free', defaultMessage: 'Free' }),
        info: item.info,
      },
    ];
    postDeductible = [
      ...category[1],
      {
        label: item.label,
        value: $t({ id: 'health-plan.free', defaultMessage: 'Free' }),
        info: item.info,
      },
    ];
  } else {
    preDeductible = [...category[0], item];
    postDeductible = [...category[1], item];
  }
  return [preDeductible, postDeductible];
}

export const BENEFIT_NAMES = {
  docVisits: $t({ id: 'health-plan.docVisits', defaultMessage: 'Doctor visits' }),
  drugs: $t({ id: 'health-plan.drugs', defaultMessage: 'Prescription drugs' }),
  labWork: $t({ id: 'health-plan.labWork', defaultMessage: 'Labs & imaging' }),
  treatAndServices: $t({
    id: 'health-plan.treatAndServices',
    defaultMessage: 'Treatment & services',
  }),
  visionDental: $t({ id: 'health-plan.visionDental', defaultMessage: 'Vision & Dental' }),
};

export const BENEFIT_ICONS = {
  docVisits: Doctor,
  drugs: Pill,
  labWork: Lab,
  treatAndServices: Medical,
  visionDental: Vision,
};

export const FAM = {
  Individual: 1,
  'Family Per Person': 2,
  Family: 3,
};

export const _fixStateMetal = (metal) => {
  return properCase(metal)?.replace('Expanded_bronze', 'Expanded Bronze');
};

export const sortDeduct = (a, b) => FAM[b?.familyCost] - FAM[a?.familyCost];

export const EHB = [
  $t({
    id: 'health-plan.ehb-outpatient',
    defaultMessage: 'Outpatient care that doesn’t require being admitted to the hospital',
  }),
  $t({ id: 'health-plan.ehb-hospitalization', defaultMessage: 'Hospitalization' }),
  $t({ id: 'health-plan.ehb-maternity', defaultMessage: 'Pregnancy, maternity, and newborn care' }),
  $t({
    id: 'health-plan.ehb-mental-health',
    defaultMessage: 'Mental health and substance use disorder services',
  }),
  $t({ id: 'health-plan.ehb-prescription-drugs', defaultMessage: 'Prescription drugs' }),
  $t({
    id: 'health-plan.ehb-rehab',
    defaultMessage: 'Rehabilitative and habilitative services and devices',
  }),
  $t({ id: 'health-plan.ehb-labs', defaultMessage: 'Laboratory services' }),
  $t({
    id: 'health-plan.ehb-preventative',
    defaultMessage: 'Preventive and wellness services and chronic disease management',
  }),
  $t({
    id: 'health-plan.ehb-pediatric',
    defaultMessage: 'Pediatric services, including oral and vision care',
  }),
  $t({ id: 'health-plan.ehb-womens', defaultMessage: 'Breastfeeding and birth control coverage' }),
];
