import { Link as GatsbyLink } from 'gatsby';
import React, { ReactNode } from 'react';
import { DOMAIN } from 'src/constants/emails';

const EMAIL_PREFIX = 'mailto:';
const PHONE_NUMBER_PREFIX = 'tel:';

interface LinkProps {
  children?: ReactNode;
  to: string;
}

export default function Link({ children, to: propsTo, ...props }: LinkProps) {
  const isEmail = propsTo.split('?')[0].includes(DOMAIN);
  const isPhoneNumber = propsTo.match(/^\d/);
  let body = children;
  let to = propsTo;

  if (isEmail) {
    body = children || propsTo.replace(EMAIL_PREFIX, '');
    to = `${EMAIL_PREFIX}${propsTo}`;
  }

  if (isPhoneNumber) {
    body = children || propsTo.replace(PHONE_NUMBER_PREFIX, '');
    to = `${PHONE_NUMBER_PREFIX}:${propsTo}`;
  }

  if (isEmail || isPhoneNumber || propsTo.includes('http')) {
    return (
      <a href={to} {...props}>
        {body}
      </a>
    );
  }

  return (
    <GatsbyLink to={to} {...props}>
      {body}
    </GatsbyLink>
  );
}
