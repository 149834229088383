import React from 'react';
import { Link as GatsbyLink } from 'gatsby';
import styled from 'styled-components';

import { $t } from 'src/utils';
import { Button, Container, Grid, Link, TextWithIcon } from '.';
import emails from 'src/constants/emails';
import phoneNumbers from 'src/constants/phone-numbers';
import { useIntercom } from 'src/lib';

const SFooter = styled.div`
  background: var(--c-bg-gray);
  padding: var(--side-margin);
  max-width: 100%;
  overflow: hidden;
  a {
    font-weight: 400;
  }
  div.xl {
    font-size: var(--fs-b2);
  }
  eb {
    margin-bottom: 24px;
    opacity: 0.65;
  }
  .disclosures {
    > div {
      padding-right: var(--side-margin);
    }
    b3 {
      margin-bottom: 12px;
      font-weight: 400;
      color: var(--c-fg-1);
      font-size: 13px;

      strong {
        font-weight: 500;
      }
    }
    a {
      text-decoration-thickness: 1px !important;
      text-decoration-color: var(--c-fg-3) !important;
      text-underline-offset: 2.5px !important;
      text-decoration: underline;

      &:hover {
        color: var(--c-fg-0);
      }
    }
  }
`;

const linkSpacing = '12px';

const Contacts = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${linkSpacing};
`;

const SLink = styled.div`
  font-size: var(--fs-b2);
  margin: ${linkSpacing} 0;

  a {
    display: flex;
    align-items: center;
  }
`;

export interface NavLinkProps {
  text: string;
  path?: string;
  onPress?: () => void;
}

export const Navlink: React.FC<NavLinkProps> = ({ text, path = '/', onPress }) => (
  <SLink>
    {/https|mailto:|tel:/.test(path) ? (
      <a className="r" onClick={onPress} href={path}>
        {text}
      </a>
    ) : (
      <Link onClick={onPress} to={path}>
        {text}
      </Link>
    )}
  </SLink>
);

/**
 * Footer
 */
export interface FooterProps {
  hideLinks?: boolean;
}

export const Footer: React.FC<FooterProps> = ({ hideLinks }) => {
  const handleIntercomOpen = useIntercom();

  return (
    <>
      <SFooter>
        <Container>
          <div className="footer">
            {!hideLinks && (
              <Grid num={4} style={{ width: '100%', marginTop: 24 }}>
                <div>
                  <eb>{$t({ id: 'footer.company', defaultMessage: 'Company' })}</eb>
                  <Navlink
                    text={$t({ id: 'footer.about', defaultMessage: 'About' })}
                    path="/about"
                  />
                  <Navlink
                    text={$t({ id: 'footer.partners', defaultMessage: 'Partners' })}
                    path="/partners"
                  />
                  <Navlink
                    text={$t({ id: 'footer.press', defaultMessage: 'Press' })}
                    path="/press"
                  />
                  <Navlink
                    text={$t({ id: 'footer.trust', defaultMessage: 'Trust' })}
                    path="/trust"
                  />
                  <Navlink
                    text={$t({ id: 'footer.licenses', defaultMessage: 'Licenses' })}
                    path="/licenses"
                  />
                  <Navlink
                    text={$t({ id: 'footer.legal', defaultMessage: 'Legal' })}
                    path="/legal"
                  />
                  {/* <Navlink text={$t({ id: "footer.careers", defaultMessage: "Careers" })} path="/careers" /> */}
                </div>
                <div>
                  <eb>{$t({ id: 'footer.resources', defaultMessage: 'Resources' })}</eb>
                  <Navlink
                    text={$t({ id: 'footer.guides', defaultMessage: 'Guides' })}
                    path="/guides/health"
                  />
                  <Navlink
                    text={$t({ id: 'footer.explorer', defaultMessage: 'Plan Explorer' })}
                    path="/explorer"
                  />
                  <Navlink text={$t({ id: 'footer.blog', defaultMessage: 'Blog' })} path="/blog" />
                  <Navlink
                    text={$t({ id: 'footer.help', defaultMessage: 'Help Center' })}
                    path="https://help.catch.co"
                  />
                </div>
                <div>
                  <eb>{$t({ id: 'footer.contact', defaultMessage: 'Get in touch' })}</eb>
                  <p>
                    {$t({
                      id: 'footer.support-hours',
                      // keep in sync with contact page SUPPORT_HOURS
                      defaultMessage:
                        'Catch Support is available from 9am to 6pm ET, Monday through Friday excluding federal holidays.',
                    })}
                  </p>
                  <Contacts>
                    <Link to={emails.HELP}>
                      <TextWithIcon block icon="Mail">
                        {emails.HELP}
                      </TextWithIcon>
                    </Link>
                    <Link to={phoneNumbers.DEFAULT}>
                      <TextWithIcon block icon="Phone">
                        {phoneNumbers.DEFAULT_FORMATTED}
                      </TextWithIcon>
                    </Link>
                    <Button variant="text" onPress={handleIntercomOpen}>
                      <TextWithIcon icon="Help">
                        {$t({
                          id: 'footer.support',
                          defaultMessage: 'Start a chat',
                        })}
                      </TextWithIcon>
                    </Button>
                  </Contacts>
                </div>
                <div style={{ display: 'none' }}>
                  <eb>{$t({ id: 'footer.follow-us', defaultMessage: 'Follow Us' })}</eb>
                  <Navlink text={$t({ id: 'footer.blog', defaultMessage: 'Blog' })} path="/blog" />
                  <Navlink
                    text={$t({ id: 'footer.twitter', defaultMessage: 'Twitter' })}
                    path="https://twitter.com/catchbenefits"
                  />
                  <Navlink
                    text={$t({ id: 'footer.instagram', defaultMessage: 'Instagram' })}
                    path="https://instagram.com/catchbenefits"
                  />
                  <Navlink
                    text={$t({ id: 'footer.facebook', defaultMessage: 'Facebook' })}
                    path="https://facebook.com/catchbenefits"
                  />
                  <Navlink
                    text={$t({ id: 'footer.linkedIn', defaultMessage: 'LinkedIn' })}
                    path="https://www.linkedin.com/company/catchbenefits"
                  />
                  {/* <Navlink text="AngelList" path="https://angel.co/company/catchco" /> */}
                  {/* <Navlink text="Crunchbase" path="https://www.crunchbase.com/organization/catchco" /> */}
                  {/* <Navlink text="OpenSea" path="https://opensea.io/catchco" /> */}
                </div>
              </Grid>
            )}
          </div>
          <div
            style={{
              padding: '32px 0 0',
              margin: '32px 0',
              borderTop: '1px solid var(--c-border)',
            }}
          >
            <b2 className="sb">&copy; {new Date().getFullYear()} Catch</b2>
          </div>
          <div className="disclosures">
            <Grid num="2">
              <div>
                <b3>
                  {$t({
                    id: 'general-disclaimer',
                    defaultMessage: `This website is operated by Catch Financial, Inc. ("Catch") 
                    and Catch Insurance, LLC ("Catch Insurance"). See {licenses}.`,
                    data: {
                      licenses: (
                        <GatsbyLink onClick={() => onPress('licenses')} to="/licenses">
                          licenses
                        </GatsbyLink>
                      ),
                    },
                  })}
                  <br />
                </b3>
              </div>
              <div>
                <b3>
                  <strong>
                    {$t({ id: 'health-insurance', defaultMessage: 'Health Insurance' })}
                  </strong>
                  <br />
                  {$t({
                    id: 'health-disclaimer',
                    defaultMessage: `Attention: This website is operated by Catch Insurance and 
                    is not the Health Insurance Marketplace® website. In offering this website, 
                    Catch Insurance is required to comply with all applicable federal law, including 
                    the standards established under 45 CFR §§155.220(c) and (d) and standards 
                    established under 45 CFR §155.260 to protect the privacy and security of personally 
                    identifiable information. This website may not support enrollment in all Qualified 
                    Health Plans (QHPs) being offered in your state through the Health Insurance 
                    Marketplace® website. For enrollment support in all available QHP options in 
                    your state, go to the Health Insurance Marketplace® website at {link}.`,
                    data: {
                      link: <a href="https://healthcare.gov">Healthcare.gov</a>,
                    },
                  })}
                </b3>
              </div>
            </Grid>
          </div>
        </Container>
      </SFooter>
    </>
  );
};

export default Footer;
